<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="areaInfoDto"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="地区名称" prop="areaName">
          <el-input v-model="areaInfoDto.areaName" style="width: 50%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addarea"
            >添加地区</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="areaName"
          label="地区名称"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          align="center"
          prop="createDate"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate | getLocalTime }}
          </template>
        </el-table-column>
       
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
        
            <el-button
              type="text"
              size="small"
              @click="updateManagerUser(scope.row)"
            >
              编辑
            </el-button>

            <el-button type="text" size="small" @click="delarea(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getarealist"
      />
    </div>
  </div>
</template>
    
    <script>
import { getarealist, delarea, addarea, updatearea } from "../../request/http";

export default {
  data() {
    return {
      title: "",
      showDialog: false,
      list: "",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },

      dialogVisible: false, //显示添加或编辑框
      areaInfoDto: {
        areaName: "",
      },
      loading:true,//加载动画

      rules: {
        areaName: [
          { required: true, message: "地区名称不能为空", trigger: "blur" },
        ],
      },
    };
  },

  created() {
    this.getarealist();
  },

  mounted() {},
  methods: {
    //跳转
    gotofarm(row) {
      let areaId = row.areaId;
      this.$router.push({
        path: "/farm",
        query: { areaId },
      });
    },

    //获取列表
    async getarealist() {
      let { data } = await getarealist(this.queryParams);
      let list = data.data;
      console.log(list);
      this.list = list.records;

      this.total = list.total;
      
      this.loading = false
    },

    // 打开添加的窗口
    addarea() {
      this.title = "添加地区信息";

      this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateManagerUser(val) {
      // 根据获取id获取详情
      this.title = "编辑地区信息";

      console.log(val);
      this.areaInfoDto = {
        areaId: val.areaId,
        areaName: val.areaName,
      };
      this.showDialog = true;
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.areaInfoDto.areaId) {
          let { data } = await updatearea(this.areaInfoDto);
          if (this.areaInfoDto.areaId && data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(data.code);
            this.$message({
              type: "error",
              message: data.code,
            });
          }
          this.getarealist();

          this.showDialog = false;
        } else {
          let { data } = await addarea(this.areaInfoDto);
          console.log(data);
          if (data.code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.getarealist();

          this.showDialog = false;
        }
      });
    },
    //弹窗取消函数
    btnCancel() {
      this.areaInfoDto = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },

    async delarea(row) {
      try {
        await this.$confirm("确定要删除该地区吗");
        console.log(row);
        let areaId = row.areaId;
        let { data } = await delarea(areaId);

        if (data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.pageNum =
          this.queryParams.pageNum > totalPage
            ? totalPage
            : this.queryParams.pageNum;
        this.queryParams.pageNum =
          this.queryParams.pageNum < 1 ? 1 : this.queryParams.pageNum;

        this.getarealist();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
    
    <style scoped>
</style>
    